import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import sal from 'sal.js';
import * as ScrollMagic from "scrollmagic";
import { TweenMax, Linear, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import 'magnific-popup';
import Swiper, { Navigation, Autoplay, autoHeight} from 'swiper';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initSal();
    this.initPreloader();
    this.initHeadroom();
    this.initFancyFocus();
    this.initSmoothScroll();
    this.initImgAnim();
    this.initSliders();
    this.initPopups();
    this.initEventFilters();
    this.initMobileDropdowns();
    this.initAccordion();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-onpage-toggle', event => this.showOnpageNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-elevent', event => this.eleventModal(event));
  },
  initSal() {
    sal();
  },
  initPreloader() {
    $(document).ready(function() {
      function hidePreloader() {
        var preloader = $('.js-preloader');
        preloader.fadeOut(500);
      }
      hidePreloader();
    });
  },
  eleventModal(event) {
    event.preventDefault();
  },
  initAccordion() {
    if ($( '.accordion' ).length) {
      $( '.js-accordion-item button' ).click(function() {
        let el = $(this);
        let svg = el.find('.accordion__single-item-icon');
        if ( el.parent().parent().hasClass('active') ) {
          el.attr('aria-expanded', 'false');
        } else {
          el.attr('aria-expanded', 'true');
        }
        el.parent().next().slideToggle('fast');
        svg.toggleClass('active');
      });
    }
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 0, tolearnce: 5 });
    headroom.init();
  },
  initImgAnim() {
    var controller = new ScrollMagic.Controller();
    $('.js-single-image').each(function() {
      var img = $(this).find('img');
      var tween = TweenMax.to(img, 1, {css: { transform: 'scale(1)' }} );
      var scene = new ScrollMagic.Scene({ triggerElement: this, duration: "100%", })
      .setTween(tween)
      .addTo(controller);
    });
  },
  initSliders() {
    Swiper.use([Autoplay]);
    Swiper.use([Navigation]);
    var swiper = new Swiper('.js-slider', {
      slidesPerView: 'auto',
      spaceBetween: 12,
      loop: true,
      breakpoints: {
        769: {
          spaceBetween: 36,
        },
      }
    });
    var logo_swiper = new Swiper('.js-logo-slider', {
      slidesPerView: 1,
      spaceBetween: 30,
      slidesPerGroup: 1,
      loopFillGroupWithBlank: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        564: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        940: {
          slidesPerView: 5,
          slidesPerGroup: 5
        }
      }
    });
    var event_swiper = new Swiper('.js-event-slider', {
      slidesPerView: 'auto',
      spaceBetween: 12,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      breakpoints: {
        1025: {
          spaceBetween: 60,
        },
      }
    });
    var slideshow_swiper = new Swiper('.js-event-slideshow', {
      autoHeight: true,
      spaceBetween: 0,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
    $(".js-event-slider").hover(function(){
      (this).swiper.autoplay.stop();
    }, function(){
      (this).swiper.autoplay.start();
    });
  },
  initPopups() {
    $(document).magnificPopup({
      delegate: '.js-popup',
      type:'inline',
      midClick: true,
      mainClass: 'popup--animate',
      callbacks: {
        close: function() {
          if ( $( ".js-internal-video" ).length ) {
            $('.js-internal-video').trigger('pause');
          }
        }
      }
    });
    $('.js-external-video-popup').magnificPopup({
      type: 'iframe',
      mainClass: 'popup--animate popup--iframe',
    });
  },
  initSmoothScroll() {
    // https://css-tricks.com/snippets/jquery/smooth-scrolling/
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      var offset = 0;
      if (screen.width < 768) {
        offset = 30;
      }
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - offset
          }, 500 );
        }
      }
    });
  },
  initMobileDropdowns() {
    $('.js-mobile-nav .menu-item-has-children').attr('aria-expanded', 'false');
    $('.js-mobile-nav .menu-item-has-children').append( '<span><svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.0008 9.5C6.2453 9.5 6.4748 9.38075 6.61505 9.17975L11.865 1.67975C12.0255 1.451 12.0435 1.15175 11.916 0.9035C11.7863 0.65525 11.5306 0.5 11.2508 0.5H0.749299C0.469549 0.5 0.213799 0.65525 0.0840492 0.9035C-0.0434508 1.15175 -0.0254508 1.451 0.135049 1.67975L5.38505 9.17975C5.5253 9.38075 5.7548 9.5 5.9993 9.5C6.00005 9.5 6.00005 9.5 6.0008 9.5C6.00005 9.5 6.00005 9.5 6.0008 9.5Z" fill="#fff"/></svg></span>');
    $('.js-mobile-nav .menu-item-has-children > span').click(function() {
      $(this).siblings('.sub-menu').slideToggle(500);
      $(this).parent().toggleClass('submenu-active');
      $(this).parent().attr('aria-expanded',
        $(this).parent().attr('aria-expanded')=='false' ? 'true' : 'false'
      );
    });
  },
  initFancyFocus() {
    var inputTrigger  = document.querySelector('#input_2_1');
    var input  = document.querySelector('#gform_wrapper_2');
    inputTrigger.addEventListener("focus", function(){
      input.classList.add('focusing');
    });
    inputTrigger.addEventListener("blur", function() {
      input.classList.remove('focusing');
    });
  },
  initEventFilters() {
    if ( $( ".building-block--events_grid" ).length || $('.building-block--past_events').length) {
      $( ".js-events-view" ).change(function() {
        var view = $('.js-events-view option').filter(':selected').val();
        $('.js-events-list').removeClass('ev-list').removeClass('ev-grid');
        $('.js-events-list').addClass(view);
      });
      $( ".js-events-submit" ).click(function() {
        var keys = $('.js-events-search').val();
        if ( keys ) {
          $('.js-events-item').hide();
          $('.js-events-item').each(function() {
            const keywords = $(this).attr('data-keywords');
            if(keywords.indexOf(keys) != -1){
              $(this).show();
            }
          });
        }

        if ( $(".js-events-item:visible").length === 0) {
          $('.js-no-events').show();
        } else {
          $('.js-no-events').hide();
        }
      });
      $( ".js-events-search" ).keyup(function(event) {
        if (event.keyCode === 13) {
          event.preventDefault();
          $( ".js-events-submit" ).click();
        }
      });
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    this.$header.toggleClass('nav-is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },
  showOnpageNav(event) {
    event.preventDefault();
    $('.js-onpage-toggle').toggleClass('is-active');
    $('.js-onpage-nav').toggleClass('is-active');
    $('.js-onpage-button').slideToggle();
    $('.js-onpage-links').slideToggle();
    if ($('.js-onpage-nav').hasClass('is-active')) {
      $('.js-onpage-links').attr('aria-expanded', 'true');
    } else {
      $('.js-onpage-links').attr('aria-expanded', 'false');
    }
  }
};

Mangrove.init();
